import React from "react"
import Layout from "../components/layout"

const WhyPage = () => (
  <Layout>
    <h1>Why are we building this platform?</h1>
    <ul>
      <li>
        There is a shift in industry from command-and-control style organizational cultures to collaborative and
        nurturing cultures
      </li>
      <li>
        Coaching helps leadership within organizations build skills and confidence in a more empowered workforce to
        solve problems
      </li>
      <li>Leverage the experience and intelligence of senior people in your workforce</li>
      <li>We help stakeholders find compliance and built consistency through collaboration</li>
    </ul>
  </Layout>
)

export default WhyPage
