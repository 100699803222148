import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo.svg"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#5C5453`,
      marginBottom: `1.45rem`,
    }}
  >
    <div className="banner">
     <h1 style={{ margin: 0, padding: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <div style={{ width: `300px`}}>
            <img src={Logo} alt="Continuous Coaching Logo"/>
          </div>
        </Link>
      </h1>
      <nav>
        <Link to={"/"}>Home</Link>
        <Link to={"/why"}>Why</Link>
        <Link to={"/what"}>What</Link>
        <Link to={"/who"}>Who</Link>
        <a href={"https://coach.continuouscoaching.ca/#/create_account"}>Sign Up</a>
        <a href={"http://coach.continuouscoaching.ca/"}>Sign In</a>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
